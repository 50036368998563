<div id="body">
  @if (!localStorageaccessible) {
    <div class="cbsw-calenso-warnings p-2">
      <app-alert
        isAlertMessageTranslated="true"
        type="warning"
        message="store_access_warning"
      />
    </div>
  }
  @if (widgetService.widgetConf?.partner?.bookingwidget_enabled === 1) {
    @if (widgetService.widgetConf && widgetService.layoutType === 'CHATBOX') {
      <app-widget-layout>
        <div mainWidgetBody>
          @if (widgetService?.widgetConf?.type === widgetTypes.LEAD_GENERATOR) {
            <app-lead-generator />
          }
          @if (widgetService?.widgetConf?.type === widgetTypes.SURVEY) {
            <app-survey />
          }
          @if (widgetService?.widgetConf?.type === widgetTypes.BOOKING) {
            <app-booking />
          }
        </div>
      </app-widget-layout>
    }

    @if (['IFRAME', 'IFRAME_URL'].includes(widgetService.layoutType)) {
      @if (widgetService.widgetConf) {
        @if (widgetService?.widgetConf?.type === widgetTypes.LEAD_GENERATOR) {
          <app-lead-generator />
        } @else if (widgetService?.widgetConf?.type === widgetTypes.SURVEY) {
          <app-survey />
        } @else if (widgetService?.widgetConf?.type === widgetTypes.BOOKING) {
          <app-booking />
        }
      } @else {
        <div class="d-flex flex-column justify-content-center align-items-center loader-container">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      }
    }
  } @else if (widgetService.widgetConf?.partner?.bookingwidget_enabled === 0) {
    <div class="card card-warning bg-white rounded card-box m-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="text-center icon-block ps-1 pe-1">
            <div class="notification-icon">
              <img src="assets/images/notifications/warning_ico.svg" />
            </div>
          </div>
          <div class="content-block ps-3 pe-3">
            <div class="notification-title text-warning">
              {{ 'error_messages.widget_disabled' | translate }}
            </div>
            <div class="notification-description mt-1">
              {{ 'error_messages.widget_disabled_description' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  }
</div>
