import { NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { fadeInFadeOut } from '@animations/fade';
import { BookingFailedViewComponent } from '@components/booking-failed-view/booking-failed-view.component';
import { BookingQuestionsViewComponent } from '@components/booking-questions-view/booking-questions-view.component';
import { BookingSuccessViewComponent } from '@components/booking-success-view/booking-success-view.component';
import { BookingSummaryViewComponent } from '@components/booking-summary-view/booking-summary-view.component';
import { MeetingTypeSelectionComponent } from '@components/meeting-type-selection/meeting-type-selection.component';
import { PersonalFormViewComponent } from '@components/personal-form-view/personal-form-view.component';
import { ServicesSelectionComponent } from '@components/services-selection/services-selection.component';
import { SlotsSelectionComponent } from '@components/slots-selection/slots-selection.component';
import { StoreSelectionComponent } from '@components/store-selection/store-selection.component';
import { WorkersSelectionComponent } from '@components/workers-selection/workers-selection.component';
import { BOOKING_PAGE_VIEWS, CALENSO_SCROLL_EVENT, SECTION_PRECEDENCE } from '@constants/app.constants';
import { WINDOW_LISTENER_CONSTANTS } from '@constants/window-listener.constants';
import { BookingPageViews } from '@models/appointment-booking.model';
import { AppointmentReservationBody } from '@models/appointment-reservation.model';
import { PartnerDbModel, TokenType } from '@models/partner-db.model';
import { WidgetTemplateModel } from '@models/widget-template.model';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { BookingService } from '@services/feature/booking.service';
import { WidgetService } from '@services/feature/widget.service';
import { HelperService } from '@services/utils/helper.service';
import { LoggerService } from '@services/utils/logger.service';
import { GtmOperationComponent } from '@ui-lib/gtm-operation/gtm-operation.component';

const components = [BookingQuestionsViewComponent, MeetingTypeSelectionComponent, StoreSelectionComponent, ServicesSelectionComponent, WorkersSelectionComponent, SlotsSelectionComponent, PersonalFormViewComponent, BookingSummaryViewComponent, BookingSuccessViewComponent, BookingFailedViewComponent, GtmOperationComponent];
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  animations: [fadeInFadeOut],
  standalone: true,
  imports: [...components, CalioSafePipe, NgClass],
})
export class BookingComponent implements OnInit {

  public widgetService = inject(WidgetService);
  public bookingService = inject(BookingService);
  private helperService = inject(HelperService);

  protected readonly bookingPageViews = BOOKING_PAGE_VIEWS;

  protected counter = 0;
  protected partnerData: PartnerDbModel;
  protected widgetTemplates: WidgetTemplateModel[] = [];
  protected isFirstBookingQuestionActive = true;
  protected isLastBookingQuestionActive = false;
  protected selectedView: BookingPageViews = BOOKING_PAGE_VIEWS.STORE_VIEW;
  protected selectedViewPrecedence = SECTION_PRECEDENCE.STORE_VIEW;
  protected showOnePageUiUpToViewPrecedence: number;
  protected sectionPrecedence = SECTION_PRECEDENCE;
  protected disableAnimation = false;  // Set this to true when you want to disable the animation.

  constructor(
  ) {
    if (this.widgetService.widgetConf?.context?.show_questions_before_booking_process) {
      this.bookingService.isDefaultPage = true;
      this.selectedView = BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW_BEFORE;
      this.selectedViewPrecedence = 0;
    } else if (this.widgetService.widgetConf?.context?.show_meeting_type_picker) {
      this.selectedView = BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW;
      this.selectedViewPrecedence = SECTION_PRECEDENCE.MEETING_TYPE_VIEW;
    }

    this.bookingService.initialSmartWidgetView = this.selectedView;
  }

  ngOnInit(): void {
    this.initializeBookingWidget(this.widgetService.partnerUUID);
  }

  private initializeBookingWidget(partnerUUID: string): void {
    this.bookingService.initializeBookingWidget(partnerUUID, TokenType.publicSmartwidget).subscribe({
      next: partner => {
        if (partner) {
          this.partnerData = partner;

          if (this.partnerData?.adobe_launch_tracking_url && this.partnerData?.adobe_launch_tracking_url?.trim() !== '') {
            this.loadAdobeAnalytics(this.partnerData.adobe_launch_tracking_url);
          }

          this.bookingService.partnerData = this.partnerData;
          this.preinitializeWidgetContextValues();
          this.getWidgetTemplates();
          if (this.widgetService.widgetConf?.context?.enable_one_page_ui) {
            this.disableAnimation = true;
            // Set default max one page UI for view to SLOTS_VIEW
            this.showOnePageUiUpToViewPrecedence = SECTION_PRECEDENCE[BOOKING_PAGE_VIEWS.SLOTS_VIEW];
          }
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  private loadAdobeAnalytics(adobe_launch_tracking_url: string): void {
    try {
      const script = document.createElement('script');
      script.src = adobe_launch_tracking_url;
      script.async = true;
      document.head.appendChild(script);
      LoggerService.log('Adobe Launch tracking script added.');
    } catch (error) {
      LoggerService.warn('Error appending dobe Launch tracking script');
      LoggerService.error(error);
    }
  }

  private preinitializeWidgetContextValues(): void {
    this.bookingService.loadAppointmentStateFromLocalStorage();
    this.bookingService.preInitializeWidgetContextValues(this.widgetService.widgetConf?.context, this.widgetService.customerConfData);
    this.bookingService.loadAppointmentSelectedUserFromLocalStorage();
    this.bookingService.preInitializeWidgetUserData(this.widgetService.customerConfData);
  }

  private getWidgetTemplates(): void {
    this.bookingService.getWidgetTemplates().subscribe({
      next: templates => {
        this.widgetTemplates = templates;
        this.bookingService.widgetTemplates = this.widgetTemplates;
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  protected gotoView(data: { view: BookingPageViews, isUserInteracted: boolean }): void {
    this.selectedView = data.view;
    this.selectedViewPrecedence = SECTION_PRECEDENCE[data.view];

    if (this.widgetService?.parentToChildOriginEvent) {
      this.widgetService.sendMessageToMainJs(this.widgetService.parentToChildOriginEvent, {
        eventName: WINDOW_LISTENER_CONSTANTS.PARENT_BOOKING_WEBSITE_EVENT,
        name: 'Child To Parent Communication Done',
        view: data.view
      });
    }

    if (data.isUserInteracted) {
      setTimeout(() => {
        const element = document.getElementById(data.view);
        const parentOrigin = window.location.ancestorOrigins[0];

        window.parent.postMessage({ eventName: CALENSO_SCROLL_EVENT, data: { top: element.getBoundingClientRect().top } }, parentOrigin || '*');
      }, 1000);
    }

    // When appointment reservation is not happen yet and user is moveing to questions or summary view then reserver it
    if (
      Number(this.partnerData.is_appointment_reservation_enabled) === 1 &&
      ([
        this.bookingPageViews.BOOKING_SUMMARY_VIEW,
        this.bookingPageViews.BOOKING_QUESTIONS_VIEW_AFTER
      ] as BookingPageViews[]).includes(data.view)
    ) {
      // Register appointments if still pending any
      const appointments: AppointmentReservationBody[] = [];
      this.bookingService.selectedBookingData.cartItems.forEach(appointment => {
        if (!appointment?.isAppointmentReserved) {
          appointments.push({
            appointment_service_id: appointment.serviceId,
            worker_id: appointment.workerId,
            start_time: appointment.startTime,
            end_time: appointment.endTime
          });
        }
      });

      // Call reservation endpoint
      if (appointments?.length) {
        this.bookingService.reserveAppointments(appointments).subscribe({
          next: reservation => {
            if (!reservation?.success) {
              LoggerService.warn("[Debug] Failed to reserve a appointment");
            } else {
              this.bookingService.selectedBookingData.cartItems.forEach(
                appointment => appointment.isAppointmentReserved = true
              );
            }
          },
          error: (error: HttpErrorResponse) => {
            LoggerService.warn("[Debug] Failed to reserve a appointment");
            LoggerService.error(error);
          }
        });
      }
    }
  }

  protected increment(): void {
    this.counter++;
  }

  protected decrement(): void {
    this.counter--;
  }

  protected showOnePageUI(view: string): boolean {
    if (this.widgetService.widgetConf?.context?.enable_one_page_ui) {
      if (this.selectedViewPrecedence >= this.sectionPrecedence[view]) {
        return true;
      }
      return false;
    }
    return false;
  }

  protected allowEditActivePage(view: BookingPageViews): boolean {
    if (this.showOnePageUI(view)) {
      if (this.selectedView === view) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }
}
