import { DecimalPipe, NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { BOOKING_PAGE_VIEWS, GTM_EVENTS, WIDGET_TEMPLATES_CONST } from '@constants/app.constants';
import { StoreDbModel } from '@models/store-db.model';
import { CurrentViewData } from '@models/widget-conf.model';
import { WidgetTemplateModel } from '@models/widget-template.model';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { ResolvePlaceholderPipe } from '@pipes/resolve-placeholder.pipe';
import { BookingService } from '@services/feature/booking.service';
import { WidgetService } from '@services/feature/widget.service';
import { GoogleAnalyticsService } from '@services/utils/google-analytics.service';
import { LoggerService } from '@services/utils/logger.service';
import { SwRouteService } from '@services/utils/sw-route.service';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { InfoCardComponent } from '@ui-lib/cards/info-card/info-card.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

const components = [CswTextComponent, InfoCardComponent, ButtonComponent];
const pipes = [DecimalPipe, CalioSafePipe, CalioTranslationPipe, CalioMeetingTemplatePipe, ResolvePlaceholderPipe];
const modules = [FormsModule, NgSelectModule, TranslateModule];

@Component({
  selector: 'app-store-selection',
  templateUrl: './store-selection.component.html',
  styleUrls: ['./store-selection.component.scss'],
  standalone: true,
  imports: [NgClass, ...pipes, components, modules],
  providers: [CalioTranslationPipe]
})
export class StoreSelectionComponent implements OnInit {

  @Output() gotoPage = new EventEmitter<CurrentViewData>();
  @Output() previousPageEvent = new EventEmitter<CurrentViewData>();

  private googleAnalyticsService = inject(GoogleAnalyticsService);
  private swRouteService = inject(SwRouteService);
  private translate = inject(TranslateService);
  public bookingService = inject(BookingService);
  public widgetService = inject(WidgetService);

  selectedStoreId: number;
  selectedStore: StoreDbModel;
  stores: StoreDbModel[];
  lang: string;
  storeLabelTemplate: WidgetTemplateModel;
  anyStoreLabelTemplate: WidgetTemplateModel;
  slotGhostElements = [1, 2, 3, 4, 5, 6];
  showGhostElement = true;
  showPreviousButton = false;
  searchStores = new EventEmitter<string>();
  isStoreLoading = false;
  selectedStoreLeadGeneratorPostcode: string;
  isGTMEventSent: boolean;
  supportedAppointmentServiceIds: number[];
  showStoreSelectionRequiredError = false;

  @ViewChild('storeForm') storeForm: NgForm;

  constructor() {
    this.searchStores.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      catchError(() => { return of([]) }),
      switchMap((term: string) => {
        this.isStoreLoading = true;
        if (term?.trim().length >= 4) {
          this.selectedStoreLeadGeneratorPostcode = term;
          this.stores = [];
          return this.bookingService.getStoresByPostCode(term);
        } else {
          return of([]);
        }
      })
    ).subscribe({
      next: stores => {
        this.stores = stores;
        if (this.stores?.length === 1) {
          this.selectedStoreId = this.stores[0].id;
          setTimeout(() => {
            this.onStoreChange(this.selectedStoreId);
            this.next();
          }, 500);
        }

        this.isStoreLoading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.isStoreLoading = false;
        LoggerService.error(error);
      }
    });

    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit(): void {
    this.isGTMEventSent = false;
    this.lang = this.translate.getDefaultLang();

    this.bookingService.loadAppointmentStateFromLocalStorage();
    this.supportedAppointmentServiceIds = this.bookingService.getSupportedAppointmentServiceIdsFromLocalStorage(
      this.bookingService.partnerData.booking_name
    );

    if (
      this.widgetService?.widgetConf?.partner?.enable_store_postcode_feature === 1 ||
      this.widgetService?.widgetConf?.partner?.automatic_store_zip_search === 1
    ) {
      if (this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreLeadGeneratorPostcode) {
        this.selectedStoreLeadGeneratorPostcode = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreLeadGeneratorPostcode;
        this.getStoresByPostCode(this.selectedStoreLeadGeneratorPostcode);
      } else {
        if (this.widgetService.isDefaultStoreIdSelected()) {
          this.fetchAvailableStores();
        } else {
          this.showGhostElement = false;
        }
      }
    } else {
      this.fetchAvailableStores();
    }

    this.setupTemplates();
    this.setupPreviousButton();
  }

  getStoresByPostCode(postcode: string): void {
    this.bookingService.getStoresByPostCode(postcode).subscribe({
      next: stores => {
        this.showGhostElement = false;
        if (stores?.length) {
          this.stores = stores;
          if (this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId) {
            this.selectedStore = this.stores.find(store =>
              (store.id === Number(this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId))
            );
            this.selectedStoreId = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId;
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore = this.selectedStore;
          }
        } else {
          this.stores = [];
        }
      },
      error: (error: HttpErrorResponse) => {
        this.showGhostElement = false;
      }
    });
  }

  setupPreviousButton(): void {
    this.widgetService?.widgetConf?.context?.show_questions_before_booking_process && (this.showPreviousButton = true);
    this.widgetService?.widgetConf?.context?.show_meeting_type_picker && (this.showPreviousButton = true);
  }

  // Fetch available stores
  fetchAvailableStores(): void {
    const appointmentServiceIds = (this.supportedAppointmentServiceIds.length ? this.supportedAppointmentServiceIds : null);
    this.bookingService.getStoresWithWorkers(appointmentServiceIds).subscribe({
      next: stores => {
        this.showGhostElement = false;
        if (stores) {
          if (!this.widgetService.widgetConf.context.is_internal) {
            this.stores = stores.filter(store => store.has_only_internal_appointment_service === 0);
          } else {
            this.stores = stores;
          }

          // Check if worker is preselected through worker preselection field then all the other stores should be disabled
          if (
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.storeAndWorkerPreSelectedThroughWorkerSelectField &&
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker
          ) {
            this.stores.map(store => {
              store.disabled = !(this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker.store_id === store.id);
            });
          }

          if (this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId) {
            this.selectedStore = this.stores.find(store =>
              (store.id === Number(this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId))
            );
            this.selectedStoreId = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId;
            this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore = this.selectedStore;

            if (this.widgetService.isDefaultStoreIdSelected()) {
              if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.SERVICES_VIEW) {
                if (this.widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
                  this.previous();
                }
              } else {
                if (this.swRouteService.isAutoSkipStepsEnabled()) {
                  this.onStoreChange(this.selectedStoreId, false);
                  this.next(false);
                }
              }
            } else {
              if (this.selectedStoreId && this.selectedStore) {
                if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.SERVICES_VIEW) {
                } else {
                  if (this.swRouteService.isAutoSkipStepsEnabled()) {
                    this.onStoreChange(this.selectedStoreId, false);
                    this.next(false);
                  }
                }
              }
            }
          } else {
            if (this.stores?.length === 1 && this.swRouteService.isAutoSkipStepsEnabled()) {
              this.selectedStoreId = this.stores[0].id;
              this.selectedStore = this.stores[0];
              this.next(false);
            }
          }
        } else {
          this.showGhostElement = false;
          this.stores = [];
        }
      },
      error: (err: HttpErrorResponse) => {
        LoggerService.error(err);
      }
    });
  }

  onStoreChange(selectedStoreId: number, isUserInteracted = true): void {
    // Reset error
    this.showStoreSelectionRequiredError = false;

    // trigger start event on first user interaction happen and
    // store selection step is configured to first step
    if (isUserInteracted) {
      this.bookingService.triggerApoointmentStartEvent();

      if (!this.isGTMEventSent) {
        this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_stores_start);
        this.isGTMEventSent = true;
      }
    }

    this.selectedStoreId = selectedStoreId;
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId = selectedStoreId;

    if (this.selectedStoreId !== 0) {
      this.selectedStore = this.stores.find(store => store.id === this.selectedStoreId);
      if (this.selectedStore?.default_worker_id?.length) {
        if (this.selectedStore.default_worker_id?.length === 1) {
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId = this.selectedStore.default_worker_id[0];
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker = this.selectedStore.default_worker[0];
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.storeDefaultWorkerIds = [];
        } else {
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId = null;
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker = null;
          this.bookingService.selectedBookingData.currentSelectedAppointmentState.storeDefaultWorkerIds = this.selectedStore.default_worker_id;
        }
      } else {
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId = null;
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker = null;
        this.bookingService.selectedBookingData.currentSelectedAppointmentState.storeDefaultWorkerIds = [];
      }
    } else {
      this.selectedStore = new StoreDbModel();
      this.selectedStore.id = 0;
    }

    // Proceeds to the next step if the one-page UI is enabled.
    this.widgetService.widgetConf?.context?.enable_one_page_ui && this.next();
  }

  previous(): void {
    this.isGTMEventSent = false;
    if (
      this.widgetService?.widgetConf?.context?.show_meeting_type_picker &&
      this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId === 1
    ) {
      this.previousPageEvent.emit({ view: BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW, isUserInteracted: true });
    } else {
      this.previousPageEvent.emit({ view: BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW_BEFORE, isUserInteracted: true });
    }
  }

  next(isUserInteracted = true): void {

    this.storeForm?.control?.markAllAsTouched();
    if (this.storeForm?.invalid) return;

    // Meeting type is is not selected and clicked on next then show error.
    if (!this.selectedStoreId && this.selectedStoreId !== 0) {
      this.showStoreSelectionRequiredError = true;
      return;
    }


    // trigger start event on first user interaction happen and
    // store selection step is configured to first step
    if (isUserInteracted) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_stores_end);
    }

    if (
      this.widgetService?.widgetConf?.partner?.enable_store_postcode_feature === 1 ||
      this.widgetService?.widgetConf?.partner?.automatic_store_zip_search === 1
    ) {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreLeadGeneratorPostcode = this.selectedStoreLeadGeneratorPostcode;
    }

    // Reset current appointment state when store is changed
    if (this.selectedStore?.id !== this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId) {
      delete this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds;
      delete this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId;
      delete this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorker;
      delete this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedDate;
    }

    const selectedMeetingTypeId = this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId;
    this.bookingService.selectedBookingData.currentSelectedAppointmentState = this.bookingService.resetCurrentSelectedAppointmentStateonStoreSelection();
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId = this.selectedStore?.id;
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore = this.selectedStore;
    this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId = selectedMeetingTypeId;


    this.bookingService.setAppointmentStateInLocalStorage(
      this.bookingService.selectedBookingData.currentSelectedAppointmentState
    );
    this.gotoPage.emit({ view: BOOKING_PAGE_VIEWS.SERVICES_VIEW, isUserInteracted });
  }

  setupTemplates(): void {
    this.storeLabelTemplate = this.bookingService.widgetTemplates.find(template => (template.identifier === WIDGET_TEMPLATES_CONST.WIDGET_APPOINTMENT_STORE_LABEL));
    this.storeLabelTemplate && (this.storeLabelTemplate.is_multi_language = 1);
    this.anyStoreLabelTemplate = this.bookingService.widgetTemplates.find(template => template.identifier === WIDGET_TEMPLATES_CONST.WIDGET_ANY_STORES);
    this.anyStoreLabelTemplate && (this.anyStoreLabelTemplate.is_multi_language = 1);
  }

  allowOnlyNumbers(event: KeyboardEvent): boolean {
    const numReg = new RegExp('^[0-9]$');
    if (numReg.test(event.key)) {
      return true;
    }
    // Only ASCII charactar in that range allowed
    const ASCIICode = event.which ? event.which : event.keyCode;

    if (ASCIICode === 8) {
      return true;
    }

    if (ASCIICode === 65 && event.metaKey) {
      return true;
    }

    return false;
  }
}
