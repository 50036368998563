@if (showError) {
  <div class="alert alert-danger">
    <div class="error-title">
      {{ errorTitle | translate }}
    </div>
    <div>
      {{ errorMessage | translate }}
    </div>
  </div>
}

@if (showGhostElement) {
  <div class="skeleton-box-card mt-4">
    <div>
      <div class="row">
        @for (dependenciesGhostElement of dependenciesGhostElements; track dependenciesGhostElement) {
          <div class="col-md-12 mb-4">
            <div class="d-flex stats-card shadow">
              <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
                <div>
                  <div class="stat-box-1"></div>
                  <div class="stat-box mt-1"></div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

@if (showPersonalDataForm) {
  <form #personalForm="ngForm" class="needs-validation" novalidate="">
    <div class="personal-form">
      <div class="mb-1 cbw-personal-form-title title">
        <app-csw-text
          [text]="( (bookingService.widgetTemplates | calioMeetingTemplate: { id: 120, type: 'template' }) | translation: 'template_content': lang )"
          customClass="step-element-title fw-600"
        />
      </div>
      <div class="mb-2 cbw-personal-form-title description">
        <app-csw-text
          [text]="( (bookingService.widgetTemplates | calioMeetingTemplate: { id: 121, type: 'template' }) | translation: 'template_content': lang )"
          [customClass]="'step-element-title'"
        />
      </div>
      @for (fieldType of fieldTypes; track fieldType) {
        @switch (fieldType.type) {
          @case ('salutation') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-salutation">
              <label class="mb-1" for="salutation">
                {{ 'form_fields.salutation' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('salutation') > -1)} }}
              </label>
              @if (widgetService.widgetConf?.context?.saluation_display_type === 'selection') {
                <ng-select
                  id="salutation"
                  name="gender"
                  [(ngModel)]="userInfo.gender"
                  #salutation="ngModel"
                  [required]="customerRequiredFields?.indexOf('salutation') > -1"
                  [clearable]="false"
                  [ngClass]="{ 'is-invalid': salutation.touched && salutation.invalid }"
                >
                  @for (salutationType of salutationTypes; track salutationType) {
                    <ng-option [value]="salutationType.value">
                      {{ 'form_fields.' + salutationType.display | translate }}
                    </ng-option>
                  }
                </ng-select>
                @if (salutation.touched && salutation.invalid) {
                  <div class="invalid-feedback">
                    @if (salutation.errors?.required) {
                      {{ 'error_messages.required_error_message' | translate }}
                    }
                  </div>
                }
              } @else if (widgetService.widgetConf?.context?.saluation_display_type === 'radio'){
                <div
                  ngModelGroup="salutationGroup"
                  #salutationGroup="ngModelGroup"
                  [required]="customerRequiredFields?.indexOf('salutation') > -1"
                  [ngClass]="{ 'is-invalid': salutationGroup?.touched && salutationGroup?.invalid }"
                >
                  @for (salutationType of salutationTypes; track $index) {
                    <div class="d-flex align-items-center mt-1 radio-item salutation-{{ salutationType.value }}">
                      <input
                        [id]="'salutation_' + salutationType.value"
                        name="salutation"
                        type="radio"
                        [value]="salutationType.value"
                        [required]="customerRequiredFields?.indexOf('salutation') > -1"
                        [(ngModel)]="userInfo.gender"
                      >

                      <label class="radio-button-label" [for]="'salutation_' + salutationType.value">
                        <span>{{ 'form_fields.' + salutationType.display | translate }}</span>
                      </label>
                    </div>
                  }
                </div>
                @if (salutationGroup?.touched && salutationGroup?.invalid) {
                  <div class="invalid-feedback">
                    {{ 'error_messages.required_error_message' | translate }}
                  </div>
                }
              }
            </div>
          }
          @case ('prename') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-prename">
              <label class="mb-1" for="firstName">{{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 220, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('prename') > -1)} ) }}</label>
              <input type="text" class="form-control" id="firstName" placeholder=""
                [required]="customerRequiredFields?.indexOf('prename') > -1"
                name="forename" [(ngModel)]="userInfo.forename"
                #forename="ngModel" [ngClass]="{ 'is-invalid': forename.touched && forename.invalid }"
              >
              @if (forename.touched && forename.invalid) {
                <div class="invalid-feedback">
                  @if (forename.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('lastname') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-lastname">
              <label class="mb-1" for="lastName">{{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 221, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('lastname') > -1)} ) }}</label>
              <input type="text" class="form-control" id="lastName" placeholder=""
                [required]="customerRequiredFields?.indexOf('lastname') > -1"
                name="lastName" [(ngModel)]="userInfo.lastName"
                #lastName="ngModel" [ngClass]="{ 'is-invalid': lastName.touched && lastName.invalid }"
              >
              @if (lastName.touched && lastName.invalid) {
                <div class="invalid-feedback">
                  @if (lastName.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('birthday') {
            <div class="mb-2 cbw-personal-form cbw-personal-form-birthday csw-date-field">
              <label class="mb-1">{{ 'form_fields.birthday' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('birthday') > -1)} }}</label>
              <mat-form-field>
                <input
                  name="birthday"
                  matInput
                  [matDatepicker]="myPicker"
                  [(ngModel)]='userInfo.birthday'
                  #birthday="ngModel"
                  [required]="customerRequiredFields?.indexOf('birthday') > -1"
                >
                <mat-datepicker-toggle matSuffix [for]="myPicker" />
                <mat-datepicker #myPicker />
              </mat-form-field>
              @if (birthday.touched && birthday.invalid) {
                <div class="text-danger">
                  @if (birthday.errors['required']) {
                    <div>
                      This field is required.
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('meeting_types') {
            @if (rawMeetingTypes?.length > 1 && showMeetingTypes) {
              <div class="mb-1 cbw-personal-form cbw-personal-form-meeting_types">
                <label class="input-label"
                    [innerHTML]="(((bookingService.widgetTemplates | calioMeetingTemplate: { id: 203, type: 'template' }) | translation: 'template_content': lang ) | addRequired: {isRequired: (customerRequiredFields?.indexOf('meeting_types') > -1)}) | calioSafe: 'html'"
                >
                  {{ 'form_fields.meeting_type' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('meeting_types') > -1)} }}
                </label>
                <ng-select
                  name="meeting_type_id"
                  [(ngModel)]="userInfo.meeting_type_id"
                  #meeting_type_id="ngModel"
                  [items]="rawMeetingTypes"
                  bindValue="id"
                  [clearable]="false"
                  [required]="customerRequiredFields?.indexOf('meeting_types') > -1"
                  #meetingTypesModel="ngModel"
                  [ngClass]="{ 'is-invalid': meetingTypesModel.touched && meetingTypesModel.invalid }"
                  (change)="onMeetingTypeChange($event)"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div class="d-flex align-items-center">
                      <div class="meeting-icon d-flex align-items-center">
                        @if (item.id === 1) {
                          <img src="{{ imagePath }}/images/meetings/store.svg" width="26px" />
                        }
                        @if (item.id === 2) {
                          <img src="{{ imagePath }}/images/meetings/zoom.svg" width="26px" />
                        }
                        @if (item.id === 3) {
                          <img src="{{ imagePath }}/images/meetings/calenso.svg" width="26px" />
                        }
                        @if (item.id === 4) {
                          <img src="{{ imagePath }}/images/meetings/phone.svg" width="26px" height="30px" />
                        }
                        @if (item.id === 5) {
                          <img src="{{ imagePath }}/images/meetings/customer.svg" width="26px" />
                        }
                        @if (item.id === 6) {
                          <img src="{{ imagePath }}/images/meetings/unblu.svg" width="26px" />
                        }
                        @if (item.id === 7) {
                          <img src="{{ imagePath }}/images/meetings/teams.svg" width="26px" />
                        }
                        @if (item.id === 8) {
                          <img src="{{ imagePath }}/images/meetings/meet.svg" width="26px" />
                        }
                        @if (item.id === 9) {
                          <img src="{{ imagePath }}/images/meetings/gotomeeting.png" width="26px" />
                        }
                        @if (item.id === 10) {
                          <img src="{{ imagePath }}/images/meetings/webex.png" width="26px" />
                        }
                        @if (item.id === 11) {
                          <img src="{{ imagePath }}/images/meetings/phone.svg" width="26px" height="30px" />
                        }
                        @if (item.id === 13) {
                          <img src="{{ imagePath }}/images/meetings/metaverse.svg" width="26px" />
                        }
                      </div>
                      @if ((bookingService.widgetTemplates | calioMeetingTemplate: { id: item.id, type: 'meeting_template' }); as widgetTemplate) {
                        <div class="meeting-option">
                          <div
                            class="meeting-type-name"
                            [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content': lang) | calioSafe: 'html'"
                          ></div>
                          <div
                            class="meeting-type-desc"
                            [innerHTML]="(widgetTemplate.descTemplate | translation: 'template_content': lang) | calioSafe: 'html'"
                          ></div>
                          @if (item.id === 1 && selectedStore) {
                            <div class="cbw-summary-worker-store-location">
                              <span class="cbw-summary-worker-store-location-name">{{ selectedStore | translation: 'name' : lang }}</span>
                              @if (selectedStore.is_online === 0) {
                                <span class="cbw-summary-worker-store-location-separator">,&nbsp;</span>
                                <span class="cbw-summary-worker-store-location-address">
                                  @if (selectedStore.street) {
                                    {{ selectedStore.street }},&nbsp;
                                  }
                                  @if (selectedStore.zip) {
                                    {{ selectedStore.zip }}&nbsp;
                                  }
                                  @if (selectedStore.city) {
                                    {{ selectedStore.city }}
                                  }
                                  @if (selectedStore.country) {
                                    ,&nbsp;{{ selectedStore.country | translation: 'name' : lang }}
                                  }
                                </span>
                              }
                            </div>
                          }
                        </div>
                      }
                    </div>
                  </ng-template>
                  <ng-template ng-label-tmp let-item="item">
                    @if ((bookingService.widgetTemplates | calioMeetingTemplate: { id: item.id, type: 'meeting_template' }); as widgetTemplate) {
                      <span [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content': lang) | calioSafe: 'html'"></span>
                    }
                  </ng-template>
                </ng-select>
                @if (meetingTypesModel.touched && meetingTypesModel.invalid) {
                  <div class="invalid-feedback">
                    @if (meetingTypesModel.errors['required']) {
                      <div>
                        {{ 'error_messages.required_error_message' | translate }}
                      </div>
                    }
                    @if (meetingTypesModel.errors['minlength']) {
                      <div>
                        {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
                      </div>
                    }
                    @if (meetingTypesModel.errors['maxlength']) {
                      <div>
                        {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
                      </div>
                    }
                  </div>
                }
              </div>
            }
          }
          @case ('notification_preferences') {
            <!-- Notification type -->
            @if (widgetService?.widgetConf?.context?.ask_for_customer_notification_preference && NOTIFICATION_TYPES?.length) {
              <div class="mb-1 cbw-personal-form cbw-personal-form-notification_preferences">
                <label class="input-label">
                  {{ 'form_fields.notification_type' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('notification_preferences') > -1)} }}
                </label>
                <ng-select
                  name="customer_notification_preference"
                  [(ngModel)]="userInfo.customer_notification_preference"
                  [clearable]="false"
                  [required]="customerRequiredFields?.indexOf('notification_preferences') > -1"
                  [ngClass]="{ 'is-invalid': customer_notification_preference.touched && customer_notification_preference.invalid }"
                  #customer_notification_preference="ngModel"
                >
                  @for (notificationType of NOTIFICATION_TYPES; track notificationType) {
                    <ng-option [value]="notificationType.value">
                      {{ 'form_fields.' + notificationType.display | translate }}
                    </ng-option>
                  }
                </ng-select>
                @if (customer_notification_preference.touched && customer_notification_preference.invalid) {
                  <div class="invalid-feedback">
                    @if (customer_notification_preference.errors['required']) {
                      <div>
                        {{ 'error_messages.required_error_message' | translate }}
                      </div>
                    }
                    @if (customer_notification_preference.errors['minlength']) {
                      <div>
                        {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
                      </div>
                    }
                    @if (customer_notification_preference.errors['maxlength']) {
                      <div>
                        {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
                      </div>
                    }
                  </div>
                }
              </div>
            }
          }
          @case ('email') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-email">
              <label class="mb-1"
              for="email">{{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 222, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('email') > -1)} ) }}</label>
              <input type="email" class="form-control" id="email"
                name="eMail" [(ngModel)]="userInfo.eMail"
                #eMail="ngModel" [ngClass]="{ 'is-invalid': eMail.touched && eMail.invalid }"
                [required]="customerRequiredFields?.indexOf('email') > -1"
                [placeholder]="((bookingService.widgetTemplates | calioMeetingTemplate: { id: 169, type: 'template' }) | translation: 'template_content': lang)"
              >
              @if (eMail.touched && eMail.invalid) {
                <div class="invalid-feedback">
                  @if (eMail.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                  @if (eMail.errors['minlength']) {
                    <div>
                      {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
                    </div>
                  }
                  @if (eMail.errors['maxlength']) {
                    <div>
                      {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
                    </div>
                  }
                  @if (eMail.errors['email']) {
                    <div>
                      {{ 'error_messages.email_error_message' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('phone') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-phone">
              <label class="mb-1">
                {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 223, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('phone') > -1)} ) }}
              </label>
              <app-calio-tel-input
                name="phone"
                [(ngModel)]="userInfo.phone"
                [defaultCountry]="(partnerData?.country?.identifier)"
                #phone="ngModel"
                [cssClass]="((phone.touched && phone.invalid) ? 'is-invalid': '')"
                [required]="customerRequiredFields?.indexOf('phone') > -1"
              />
              @if (phone.touched && phone.invalid) {
                <div class="error-message">
                  @if (phone.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                  @if (phone.errors['invalidNumber']) {
                    <div>
                      {{ 'error_messages.invalid_phone_number' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('mobile') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-mobile">
              <label class="mb-1">
                {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 263, type: 'template' }) | translation: 'template_content': lang | addRequired: {isRequired: (customerRequiredFields?.indexOf('mobile') > -1)} ) }}
              </label>
              <app-calio-tel-input
                name="mobile"
                [(ngModel)]="userInfo.mobile"
                [defaultCountry]="(partnerData?.country?.identifier)"
                #mobile="ngModel"
                [cssClass]="((mobile.touched && mobile.invalid) ? 'is-invalid': '')"
                [required]="customerRequiredFields?.indexOf('mobile') > -1"
              />
              @if (mobile.touched && mobile.invalid) {
                <div class="error-message">
                  @if (mobile.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                  @if (mobile.errors['invalidNumber']) {
                    <div>
                      {{ 'error_messages.invalid_phone_number' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('address') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-street">
              <label class="mb-1" for="street">{{ 'form_fields.street' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('address') > -1)} }}</label>
              <input type="text" class="form-control" id="street"
                name="street" [(ngModel)]="userInfo.street"
                #street="ngModel" [ngClass]="{ 'is-invalid': street.touched && street.invalid }"
                [required]="customerRequiredFields?.indexOf('address') > -1"
              >
              @if (street.touched && street.invalid) {
                <div class="invalid-feedback">
                  @if (street.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                  @if (street.errors['minlength']) {
                    <div>
                      {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
                    </div>
                  }
                  @if (street.errors['maxlength']) {
                    <div>
                      {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
                    </div>
                  }
                </div>
              }
            </div>
            <div class="row mb-1 cbw-personal-form cbw-personal-form-zip">
              <div class="col-4">
                <label
                  class="mb-1"
                  for="zip">{{ 'form_fields.zip' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('address') > -1)} }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="zip"
                  [required]="customerRequiredFields?.indexOf('address') > -1"
                  name="zip"
                  [(ngModel)]="userInfo.zip"
                  #zip="ngModel"
                  pattern="^[a-zA-Z0-9\s-]{1,10}$"
                  [ngClass]="{ 'is-invalid': zip.touched && zip.invalid }"
                >
                @if (zip.touched && zip.invalid) {
                  <div class="invalid-feedback">
                    @if (zip.errors['required']) {
                      <div>
                        {{ 'error_messages.required_error_message' | translate }}
                      </div>
                    }
                    @if (zip.errors['pattern']) {
                      <div>
                        {{ 'error_messages.invalid_zip' | translate }}
                      </div>
                    }
                  </div>
                }
              </div>
              <div class="col-8">
                <label class="mb-1" for="city">
                  {{ 'form_fields.city' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('address') > -1)} }}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="city"
                  [required]="customerRequiredFields?.indexOf('address') > -1"
                  name="city"
                  [(ngModel)]="userInfo.city"
                  #city="ngModel"
                  [ngClass]="{ 'is-invalid': city.touched && city.invalid }"
                >
                @if (city.touched && city.invalid) {
                  <div class="invalid-feedback">
                    @if (city.errors['required']) {
                      <div>
                        {{ 'error_messages.required_error_message' | translate }}
                      </div>
                    }
                    @if (city.errors['minlength']) {
                      <div>
                        {{ 'error_messages.minimum_length_error_message' | translate: {'char': 4} }}
                      </div>
                    }
                    @if (city.errors['maxlength']) {
                      <div>
                        {{ 'error_messages.max_char_error_messgae' | translate: {'char': 6} }}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          }
          @case ('comment') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-comment">
              <label class="mb-1" for="firstName">
                {{ 'form_fields.comment' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('comment') > -1)} }}
              </label>
              <textarea
                class="form-control"
                id="comment"
                placeholder=""
                [required]="customerRequiredFields?.indexOf('comment') > -1"
                name="comment" [(ngModel)]="userInfo.comment"
                #comment="ngModel"
                [ngClass]="{ 'is-invalid': comment.touched && comment.invalid }"
                cols="30"
                rows="5">
              </textarea>
              <small class="calio-text-muted">
                {{ 'form_fields.comment_helper_text' | translate }}
              </small>
              @if (comment.touched && comment.invalid) {
                <div class="invalid-feedback">
                  @if (comment.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('title') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-title">
              <label for="title">
                {{ 'personal_form.title' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('title') > -1)}  }}
              </label>
              <ng-select
                id="title"
                name="title"
                [(ngModel)]="userInfo.title"
                #title="ngModel"
                [required]="customerRequiredFields?.indexOf('title') > -1"
                [clearable]="false"
                [ngClass]="{ 'is-invalid': title.touched && title.invalid }"
              >
                @for (title of titleTypes; track title) {
                  <ng-option [value]="title.id">
                    {{ title.name | translate }}
                  </ng-option>
                }
              </ng-select>
              @if (title.touched && title.invalid) {
                <div class="invalid-feedback">
                  @if (title.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('company') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-company">
              <label class="mb-1" for="company">
                {{ 'personal_form.company' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('company') > -1)}  }}
              </label>
              <input
                type="text"
                class="form-control"
                id="company"
                placeholder=""
                [required]="customerRequiredFields?.indexOf('company') > -1"
                name="company"
                [(ngModel)]="userInfo.company"
                #company="ngModel"
                [ngClass]="{ 'is-invalid': company.touched && company.invalid }"
              >
              @if (company.touched && company.invalid) {
                <div class="invalid-feedback">
                  @if (company.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('country') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-country">
              <label class="input-label">
                {{ 'personal_form.country' | translate | addRequired: {isRequired: (customerRequiredFields?.indexOf('country') > -1)} }}
              </label>
              <ng-select
                id="country"
                name="country"
                [(ngModel)]="userInfo.country"
                [clearable]="false"
                [required]="customerRequiredFields?.indexOf('country') > -1"
                [ngClass]="{ 'is-invalid': country.touched && country.invalid }"
                #country="ngModel"
              >
                @for (option of (countries | sortTranslationList: 'name': lang); track option) {
                  <ng-option [value]="option.id">
                    <div>
                      <span>
                        {{ option | translation: 'name': lang }}
                      </span>
                    </div>
                  </ng-option>
                }
              </ng-select>
              @if (country.touched && country.invalid) {
                <div class="invalid-feedback">
                  @if (country.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('policy') {
            <div class="mb-1 cbw-personal-form cbw-personal-form-policy">
              <div class="custom-control custom-checkbox my-1">
                <input
                  id="policy"
                  name="policy"
                  for="policy"
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="userInfo.policy"
                  #policy="ngModel"
                  [ngClass]="{ 'is-invalid': policy.touched && policy.invalid }"
                  [required]="customerRequiredFields.indexOf('policy') > -1"
                >
                <label class="custom-control-label ps-2">
                  <span [innerHtml]="('personal_form.accept_data_policy' | translate) | trustHtml" ></span>
                  @if (customerRequiredFields?.indexOf('policy') > -1) {
                    <span>*</span>
                  }
                </label>
              </div>
              @if (policy.touched && policy.invalid) {
                <div class="text-danger">
                  @if (policy.errors['required']) {
                    <div>
                      {{ 'error_messages.required_error_message' | translate }}
                    </div>
                  }
                </div>
              }
            </div>
          }
          @case ('CUSTOM_FIELD') {
            @if (widgetQuestions[fieldType.value]) {
              <div class="mb-2">
                <app-booking-question
                  [customField]="widgetQuestions[fieldType.value]"
                  [selectedCustomFieldValues]="selectedCustomFieldValues"
                  (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
                />
                @if (widgetQuestions[fieldType.value]?.customFieldDependencies?.length) {
                  @for (dependencyCustomField of widgetQuestions[fieldType.value]?.customFieldDependencies; track dependencyCustomField; let childIndex = $index; let childIsFirst = $first; let childIsLast = $last) {
                    <app-booking-question
                      [customField]="dependencyCustomField"
                      [selectedCustomFieldValues]="selectedCustomFieldValues"
                      (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
                    />
                  }
                }
                @if (widgetQuestions[fieldType.value]?.showDependenciesGhostElement) {
                  <div class="skeleton-box-card mt-4">
                    <div>
                      <div class="row">
                        @for (dependenciesGhostElement of dependenciesGhostElements; track dependenciesGhostElement) {
                          <div class="col-md-12 mb-4">
                            <div class="d-flex stats-card shadow">
                              <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
                                <div>
                                  <div class="stat-box-1"></div>
                                  <div class="stat-box mt-1"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          }
        }
      }
    </div>
    <div class="d-flex justify-content-end mt-4 cbw-custom-navigation">
      <div class="me-2">
        <app-button
          id="previous-btn"
          id="{{ 'personal-form-previous-btn' }}"
          (clickEvent)="previousQuestion()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color">
          <span class="custom-button-icon back"></span>
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 129, type: 'template' }) | translation: 'template_content': lang )}}
        </app-button>
      </div>
      <div>
        <app-button
          id="{{ 'personal-form-finish-btn' }}"
          (clickEvent)="submit(personalForm)"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 128, type: 'template' }) | translation: 'template_content': lang )}}
          <span class="custom-button-icon next"></span>
        </app-button>
      </div>
    </div>
  </form>
}
